/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { gql } from '@apollo/client'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import React from 'react'
import { Link, useLocation } from 'react-router'

import {
  appHelpLink,
  newDocumentHistory,
  useBrowserExport
} from '../../components/feature-flags'
import Loading from '../../components/loading'
import { ModalPage } from '../../components/modal-page'
import { GraphQLError as Error } from '../../components/system-error'
import { DatePicker } from '../../components/temporal-pickers'
import Tooltip, { TooltipTrigger } from '../../components/tooltip'
import { useIds } from '../../components/use-ids'
import { useQuery } from '../../components/use-query'
import Formbot from '../../formbot'
import * as Icons from '../../icons'
import { useAlerts } from '../../ui/alerts'
import Checkbox from '../../ui/checkbox'
import HelpLinkInput from './help-link-input'
import { useUpdateDocumentTitlesMutation } from './mutation.update-document-titles'
import { useUpdateFormSettingsMutation } from './mutation.update-form-settings'

export default () => {
  return (
    <ModalPage title={i18n._('form.settings')} side>
      <FormSettings />
    </ModalPage>
  )
}

const INTEGRATION_TYPES = ['IntegrationTypeahead', 'IntegrationFill']

const VALID_LABEL_TYPES = [
  'Text',
  'Textarea',
  'Email',
  'Terms',
  ...INTEGRATION_TYPES
]

const FormSettings = () => {
  const { appId, datasetId, tableId } = useIds()
  const location = useLocation()
  const q = getFormSettingsPageQuery(appId, datasetId)
  const { loading, error, data } = useQuery(q)
  const alerts = useAlerts()
  const updateFormSettings = useUpdateFormSettingsMutation(appId, datasetId)
  const updateDocumentTitles = useUpdateDocumentTitlesMutation(appId, datasetId)
  const update = React.useCallback(
    val =>
      updateFormSettings({
        ...data.app.dataset,
        formSchedule: {
          enabled: data.app.dataset.formSchedule?.enabled,
          startDate: data.app.dataset.formSchedule?.startDate,
          endDate: data.app.dataset.formSchedule?.endDate
        },
        limitSubmissionsForUser: {
          enabled: data.app.dataset.limitSubmissionsForUser?.enabled,
          limit: data.app.dataset.limitSubmissionsForUser?.limit
        },
        limitSubmissionsForDataset: {
          enabled: data.app.dataset.limitSubmissionsForDataset?.enabled,
          limit: data.app.dataset.limitSubmissionsForDataset?.limit
        },
        ...val
      })
        .then(() =>
          alerts.type3(i18n._('pagesbuilder.form.settings.saved'), 'success')
        )
        .catch(() =>
          alerts.type3(i18n._('pagesbuilder.form.settings.error'), 'error')
        ),
    [updateFormSettings, alerts, data]
  )
  const formSettings = data?.app?.dataset?.formSchedule

  if (loading) return <Loading />
  if (error) return <Error error={error} />

  const integrationFields = data.app?.dataset?.formContainer?.schema
    .filter(el => INTEGRATION_TYPES.includes(el.type))
    ?.flatMap(gad =>
      gad.details.outputFields?.flatMap(g => `${gad.formKey}.${g.path}`)
    )

  const timezone = data?.tenant?.timezone
  const startDate = formSettings?.startDate
    ? new Date(Number(formSettings?.startDate))
    : ''

  const endDate = formSettings?.endDate
    ? getDateValue(new Date(Number(formSettings?.endDate)), 'end', timezone)
    : ''

  const formScheduleEnabled = formSettings?.enabled

  const limitSubmissionsForUser = data.app?.dataset?.limitSubmissionsForUser
  const limitSubmissionsForUserEnabled = limitSubmissionsForUser?.enabled

  const limitSubmissionsForDataset =
    data.app?.dataset?.limitSubmissionsForDataset
  const limitSubmissionsForDatasetEnabled = limitSubmissionsForDataset?.enabled

  const titleOptions = data.app?.dataset?.formContainer?.schema
    ?.filter(
      field =>
        VALID_LABEL_TYPES.includes(field.type) &&
        !integrationFields?.includes(field.formKey)
    )
    .map(field => {
      if (['Terms', ...INTEGRATION_TYPES].includes(field.type)) {
        return { ...field, formKey: field.formKey + '.label' }
      }
      return field
    })
    .map(field => (
      <option key={field.formKey} value={field.formKey}>
        {field.label}
      </option>
    ))

  const offerNewPdfExperience =
    data.app?.dataset?.allowExport && useBrowserExport
  const offerAttachments =
    offerNewPdfExperience && data.app?.dataset?.useBrowserExport

  return (
    <div className='mx-auto mb-12 mt-8 w-[420px] text-sm max-[500px]:max-w-[90%]'>
      <h3 className='text-xl font-medium'>
        <Trans id='pagesbuilder.form.settings.options' />
      </h3>
      <div className='pb-1 pt-4 text-xs text-medium-gray-500'>
        <div className='flex items-center justify-between'>
          <span>
            <Trans id='pagesbuilder.form.settings.title.field' />
          </span>
          <TooltipTrigger
            tooltipId='form-title-field-help'
            label={i18n._('pagesbuilder.form.settings.form.title.field')}
          >
            <Icons.AlertHelp className='ml-1 fill-blue-500' />
          </TooltipTrigger>
          <Tooltip id='form-title-field-help' place='bottom'>
            <div className='w-44'>
              <Trans id='pagesbuilder.form.settings.title.field.help' />
            </div>
          </Tooltip>
        </div>
      </div>
      <select
        className='kp-select'
        value={data.app?.dataset?.titleField ?? undefined}
        onChange={e => {
          update({ titleField: e.target.value })
          updateDocumentTitles(e.target.value)
        }}
      >
        {titleOptions}
      </select>
      {data.app?.dataset?.allowNewVersions && (
        <>
          <div className='pb-1 pt-5 text-xs text-medium-gray-500'>
            <div className='flex items-center justify-between'>
              <span>
                <Trans id='version.title.field.colon' />
              </span>
              <TooltipTrigger
                tooltipId='version-title-field-help'
                label={i18n._('pagesbuilder.form.settings.version.title')}
              >
                <Icons.AlertHelp className='ml-1 fill-blue-500' />
              </TooltipTrigger>
              <Tooltip id='version-title-field-help' place='bottom'>
                <div className='w-44'>
                  <Trans id='pagesbuilder.form.settings.version.title.help' />
                </div>
              </Tooltip>
            </div>
          </div>
          <select
            className='kp-select'
            value={data.app?.dataset?.versionTitleField}
            onChange={e => {
              update({ versionTitleField: e.target.value })
              updateDocumentTitles(e.target.value, 'VERSION')
            }}
          >
            <option value=''>
              <Trans id='pagesbuilder.form.settings.date' />
            </option>
            {titleOptions}
          </select>
        </>
      )}
      <div className='pb-1 pt-5 text-xs text-medium-gray-500'>
        <Trans id='pagesbuilder.form.settings.label.font.size' />
      </div>
      <select
        className='kp-select'
        value={data.app?.dataset?.labelSize ?? 'medium'}
        onChange={e => update({ labelSize: e.target.value })}
      >
        <option value='medium'>
          <Trans id='pagesbuilder.form.settings.medium' />
          (default)
        </option>
        <option value='large'>
          <Trans id='pagesbuilder.form.settings.large' />
        </option>
        <option value='extra-large'>
          <Trans id='pagesbuilder.form.settings.extralarge' />
        </option>
        <option value='huge'>
          <Trans id='pagesbuilder.form.settings.huge' />
        </option>
      </select>
      <div className='pb-1 pt-5 text-xs text-medium-gray-500'>
        <Trans id='pagesbuilder.form.settings.font.preview' />
      </div>
      <Formbot.View
        context={{ labelSize: data.app?.dataset?.labelSize }}
        document={{ data: { name: 'Jane Smith' } }}
        structure={{
          template: {
            id: 'a',
            type: 'Section',
            label: `${i18n._('pagesbuilder.form.settings.section.name')}`,
            children: [
              {
                id: 'b',
                type: 'Text',
                label: `${i18n._('pagesbuilder.form.settings.full.name')}`,
                formKey: 'name',
                description: {
                  enabled: true,
                  value: `${i18n._('pagesbuilder.form.settings.help.text')}`
                }
              }
            ]
          },
          metaFields: [],
          integrationFields: [],
          trashed: []
        }}
      />
      <div className='mt-8 flex w-full flex-col gap-2'>
        <h3 className='text-xl font-medium'>
          <Trans
            id='pagesbuilder.form.settings.submission.settings'
            message='Submission Settings'
          />
        </h3>
        <label className='mb-2 flex items-center gap-2'>
          <input
            type='checkbox'
            className='kp-checkbox'
            checked={formScheduleEnabled}
            onChange={() =>
              update({
                formSchedule: {
                  enabled: !data.app.dataset.formSchedule?.enabled,
                  startDate: data.app.dataset.formSchedule?.startDate,
                  endDate: data.app.dataset.formSchedule?.endDate
                }
              })
            }
          />
          <Trans
            id='pagesbuilder.form.settings.set.submission.dates'
            message='Set submission dates'
          />
        </label>
        {formScheduleEnabled && (
          <>
            <div className='w-full'>
              <div className='pb-1 pt-2 text-xs text-medium-gray-500'>
                <Trans id='pagesbuilder.form.settings.start.date' />
              </div>
              <div className='flex'>
                <DatePicker
                  className='box-border h-8 w-full min-w-0 rounded border border-light-gray-500 bg-white p-0 px-2 text-sm font-normal'
                  placeholder={i18n._('pagesbuilder.form.settings.select.date')}
                  maxDate={endDate}
                  value={startDate}
                  onChange={val =>
                    update({
                      formSchedule: {
                        enabled: data.app.dataset.formSchedule?.enabled,
                        startDate: getCorrectDate(val, timezone, 'start'),
                        endDate: data.app.dataset.formSchedule?.endDate
                      }
                    })
                  }
                />
                {data.app.dataset.formSchedule?.startDate && (
                  <button
                    className='kp-button-transparent kp-button-icon text-medium-gray-500'
                    onClick={() =>
                      update({
                        formSchedule: {
                          enabled: data.app.dataset.formSchedule?.enabled,
                          startDate: null,
                          endDate: data.app.dataset.formSchedule?.endDate
                        }
                      })
                    }
                  >
                    <Icons.Close />
                  </button>
                )}
              </div>
              <div className='pt-0.5 text-xs text-medium-gray-500'>
                {Intl.DateTimeFormat('en-US', {
                  timeStyle: 'long'
                }).format(
                  getDateValue(
                    startDate !== '' ? startDate : '',
                    'start',
                    timezone
                  )
                )}
              </div>
            </div>
            <div className='w-full'>
              <div className='pb-1 pt-2 text-xs text-medium-gray-500'>
                <Trans id='pagesbuilder.form.settings.end.date' />
              </div>
              <div className='flex'>
                <DatePicker
                  className='box-border h-8 w-full min-w-0 rounded border border-light-gray-500 bg-white p-0 px-2 text-sm font-normal'
                  placeholder={i18n._('pagesbuilder.form.settings.select.date')}
                  value={endDate}
                  minDate={startDate}
                  onChange={val =>
                    update({
                      formSchedule: {
                        enabled: data.app.dataset.formSchedule?.enabled,
                        startDate: data.app.dataset.formSchedule?.startDate,
                        endDate: getCorrectDate(val, timezone, 'end')
                      }
                    })
                  }
                />
                {data.app.dataset.formSchedule?.endDate && (
                  <button
                    className='kp-button-transparent kp-button-icon text-medium-gray-500'
                    onClick={() =>
                      update({
                        formSchedule: {
                          enabled: data.app.dataset.formSchedule?.enabled,
                          startDate: data.app.dataset.formSchedule?.startDate,
                          endDate: null
                        }
                      })
                    }
                  >
                    <Icons.Close />
                  </button>
                )}
              </div>
              <div className='py-0.5 text-xs text-medium-gray-500'>
                {Intl.DateTimeFormat('en-US', {
                  timeStyle: 'long'
                }).format(
                  getDateValue(endDate !== '' ? endDate : '', 'end', timezone)
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className='flex items-center gap-2'>
        <label className='flex items-center gap-2'>
          <input
            type='checkbox'
            className='kp-checkbox'
            checked={data.app?.dataset?.submissionsDisabled ?? false}
            onChange={e => update({ submissionsDisabled: e.target.checked })}
          />
          <Trans
            id='pagesbuilder.form.settings.disable.submissions'
            message='Disable submissions'
          />
        </label>

        <TooltipTrigger
          tooltipId='disable-submissions-field-help'
          label={i18n._('pagesbuilder.form.settings.disable.submissions.field')}
        >
          <Icons.AlertHelp className='ml-1 fill-blue-500' />
        </TooltipTrigger>
        <Tooltip id='disable-submissions-field-help' place='bottom'>
          <div className='w-44'>
            <Trans id='pagesbuilder.form.settings.prevent.submit' />
          </div>
        </Tooltip>
      </div>
      <label className='mt-2 flex items-center gap-2'>
        <input
          type='checkbox'
          className='kp-checkbox'
          checked={limitSubmissionsForUserEnabled}
          onChange={() =>
            update({
              limitSubmissionsForUser: {
                enabled: !data.app.dataset.limitSubmissionsForUser?.enabled,
                limit: 1
              }
            })
          }
        />
        <Trans
          id='pagesbuilder.form.settings.set.submission.limit.user'
          message='Limit submissions to one per user'
        />
      </label>
      <div>
        <label className='mt-2 flex items-center gap-2'>
          <input
            type='checkbox'
            className='kp-checkbox'
            checked={limitSubmissionsForDatasetEnabled}
            onChange={() =>
              update({
                limitSubmissionsForDataset: {
                  enabled:
                    !data.app.dataset.limitSubmissionsForDataset?.enabled,
                  limit:
                    data.app.dataset.limitSubmissionsForDataset.limit ?? 100
                }
              })
            }
          />
          <Trans
            id='pagesbuilder.form.settings.set.submission.limit.dataset'
            message='Limit total submissions for this dataset.'
          />
        </label>
        {limitSubmissionsForDatasetEnabled && (
          <>
            <label
              className='block pb-1 pt-2 text-xs text-medium-gray-500'
              htmlFor='app-submission-limit'
            >
              <Trans id='pagesbuilder.form.settings.submission.limit.dataset' />
            </label>
            <input
              className='kp-input w-full'
              id='app-submission-limit'
              type='number'
              value={limitSubmissionsForDataset?.limit}
              onChange={e =>
                update({
                  limitSubmissionsForDataset: {
                    enabled:
                      data.app.dataset.limitSubmissionsForDataset?.enabled,
                    limit: parseInt(e.target.value ?? 0)
                  }
                })
              }
            />
          </>
        )}
      </div>
      <>
        <h3 className='mb-2 mt-6 text-xl font-medium'>
          <Trans id='pagesbuilder.form.settings.post.submission' />
        </h3>
        <span className='text-medium-gray-500'>
          <Trans id='pagesbuilder.form.settings.post.submission.data' />
        </span>
        <Checkbox
          rounded
          label={i18n._('pagesbuilder.form.settings.show.new.confirm')}
          checked={data.app?.dataset?.showNewFormButton ?? false}
          onChange={val =>
            update({
              showNewFormButton: val,
              autoStartNewForm: val ? false : data.app.dataset.autoStartNewForm
            })
          }
        />
        <Checkbox
          rounded
          label={i18n._('pagesbuilder.form.settings.start.new.empty')}
          checked={data.app?.dataset?.autoStartNewForm ?? false}
          onChange={val =>
            update({
              autoStartNewForm: val,
              showNewFormButton: val
                ? false
                : data.app.dataset.showNewFormButton
            })
          }
        />
        {newDocumentHistory && (
          <>
            <h3 className='mb-2 mt-6 text-xl font-medium'>
              <Trans id='pagesbuilder.form.settings.document.history' />
            </h3>
            <div className='flex items-center gap-2'>
              <Checkbox
                label={i18n._('pagesbuilder.form.settings.track.edits')}
                checked={data.app?.dataset?.trackDocumentEdits ?? false}
                onChange={val => update({ trackDocumentEdits: val })}
              />

              <TooltipTrigger
                tooltipId='track-edits-help'
                label={i18n._('pagesbuilder.form.settings.track.edits.help')}
              >
                <Icons.AlertHelp className='ml-1 fill-blue-500' />
              </TooltipTrigger>
              <Tooltip id='track-edits-help' place='bottom'>
                <div className='w-44'>
                  <Trans id='pagesbuilder.form.settings.track.edits.help' />
                </div>
              </Tooltip>
            </div>
          </>
        )}
        <h3 className='mb-2 mt-6 text-xl font-medium'>
          <Trans id='pagesbuilder.form.settings.additional' />
        </h3>
        <Checkbox
          label={i18n._('pagesbuilder.form.settings.turn.top.sections')}
          checked={data.app?.dataset?.paginated ?? false}
          onChange={val => update({ paginated: val })}
        />
        <div className='flex flex-col gap-2'>
          <Checkbox
            label={i18n._('pagesbuilder.form.settings.allow.exporting')}
            checked={data.app?.dataset?.allowExport ?? false}
            onChange={() =>
              update({ allowExport: !data.app?.dataset?.allowExport })
            }
          />
          {offerNewPdfExperience && (
            <div className='flex flex-col gap-2'>
              <div className='ml-4 flex items-center'>
                <label className='flex items-center gap-2'>
                  <input
                    type='checkbox'
                    className='kp-checkbox'
                    checked={data.app?.dataset?.useBrowserExport ?? false}
                    onChange={() =>
                      update({
                        useBrowserExport: !data.app?.dataset?.useBrowserExport
                      })
                    }
                  />
                  <Trans
                    id='pagesbuilder.form.settings.new.pdf.experience'
                    message='New PDF Experience'
                  />
                </label>

                <span className='kp-badge ml-2 self-center bg-wintergreen-300 text-center font-medium'>
                  <Trans id='pagesbuilder.form.settings.preview' />
                </span>

                <Tooltip id='new-pdf-experience-help' place='bottom'>
                  <p>
                    <Trans
                      id='pagesbuilder.form.settings.new.pdf.experience.help'
                      message='Will generate a PDF that is as close to the real form as possible'
                    />
                  </p>
                </Tooltip>
                <TooltipTrigger
                  tooltipId='new-pdf-experience-help'
                  label='New PDF Experience'
                >
                  <Icons.AlertHelp className='ml-1 fill-blue-500' />
                </TooltipTrigger>
              </div>

              {offerAttachments && (
                <div className='ml-8'>
                  <label className='flex items-center gap-2'>
                    <input
                      type='checkbox'
                      className='kp-checkbox'
                      checked={
                        data.app?.dataset?.includePdfAttachments ?? false
                      }
                      onChange={() =>
                        update({
                          includePdfAttachments:
                            !data.app?.dataset?.includePdfAttachments
                        })
                      }
                    />
                    <Trans
                      id='pagesbuilder.form.settings.include.attachments'
                      message='Include PDFs uploaded through the form'
                    />
                  </label>
                </div>
              )}
            </div>
          )}
        </div>

        <div className='mt-2'>
          <label className='flex items-center gap-2'>
            <input
              type='checkbox'
              className='kp-checkbox'
              checked={data.app?.dataset?.removeIntegrationOnDuplicate ?? false}
              onChange={() =>
                update({
                  removeIntegrationOnDuplicate:
                    !data.app?.dataset?.removeIntegrationOnDuplicate
                })
              }
            />
            <Trans
              id='pagesbuilder.form.settings.remove.integration'
              message='Remove integration on duplicate'
            />
          </label>
        </div>
        {appHelpLink && (
          <HelpLinkInput appId={data.app?.id} helpLink={data.app?.helpLink} />
        )}
      </>
      <div className='mt-8 flex w-full flex-col items-start gap-y-2'>
        <div className='flex items-center gap-2'>
          <h3 className='text-xl font-medium'>
            <Trans id='pagesbuilder.form.settings.conditional' />
          </h3>
        </div>
        <div>
          <Trans id='pagesbuilder.form.settings.conditional.data' />
        </div>
        <div className='flex items-center bg-white dark:bg-light-gray-300'>
          <div className='flex flex-col justify-center self-stretch bg-blue-500 p-4'>
            <Icons.AlertInfo className='fill-white' width={24} height={24} />
          </div>
          <p className='py-4 pl-4'>
            <Trans
              id='pagesbuilder.form.settings.requires.list'
              components={{
                link: (
                  <Link
                    className='text-text-link underline'
                    to={tableId ? '../../../../permissions' : '../permissions'}
                    state={{ back: location.pathname }}
                  />
                )
              }}
            />
          </p>
        </div>
        <Link
          className='kp-button-outline kp-button-sm relative'
          to='conditional-permissions'
        >
          <Trans id='pagesbuilder.form.settings.edit.conditional' />
        </Link>
      </div>
    </div>
  )
}

function getDateValue (date, startOrEnd = 'start', timeZone) {
  if (date === '' || date == null) {
    date = new Date(Number(getCorrectDate(new Date(), timeZone, 'start')))
  }
  if (startOrEnd === 'start') return date
  date.setSeconds(date.getSeconds() - 1)
  return date
}

function getCorrectDate (val = new Date(), timezone, startOrEnd) {
  let utcMidnight
  if (startOrEnd === 'start') {
    utcMidnight = new Date(
      Date.UTC(val.getFullYear(), val.getMonth(), val.getDate())
    )
  } else {
    utcMidnight = new Date(
      Date.UTC(val.getFullYear(), val.getMonth(), val.getDate() + 1)
    )
  }
  const offset = getTimezoneOffset(timezone, utcMidnight)
  return `${utcMidnight.getTime() - offset}`
}

function getTimezoneOffset (timeZone) {
  const timeZoneName = Intl.DateTimeFormat('ia', {
    timeZoneName: 'shortOffset',
    timeZone
  })
    .formatToParts()
    .find(i => i.type === 'timeZoneName')?.value
  const offset = timeZoneName?.slice(3)
  if (!offset) return 0
  const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/)
  const compiledError = `${
    i18n._('pagesbuilder.form.settings.cannot.parse') + { timeZoneName }
  }`
  if (!matchData) throw new Error(compiledError)

  const [, sign, hour, minute] = matchData
  let result = parseInt(hour) * 60
  if (sign === '-') result *= -1
  if (minute) result += parseInt(minute)

  return result * 60 * 1000
}

const getFormSettingsPageQuery = (appId, pageId) => ({
  variables: { appId, pageId },
  fetchPolicy: 'cache-and-network',
  query: gql`
    query FormSettingsPageQuery($appId: ID!, $pageId: ID) {
      app(id: $appId) {
        id
        type
        helpLink
        dataset(id: $pageId) {
          allowNewVersions
          id
          autoStartNewForm
          titleField
          versionTitleField
          labelSize
          paginated
          showNewFormButton
          formSchedule {
            enabled
            startDate
            endDate
          }
          allowExport
          useBrowserExport
          includePdfAttachments
          removeIntegrationOnDuplicate
          submissionsDisabled
          limitSubmissionsForUser {
            enabled
            limit
          }
          limitSubmissionsForDataset {
            enabled
            limit
          }
          trackDocumentEdits

          # is this right? or is there a better way to get all past/present fields (look at doc list)
          formContainer {
            id
            schema {
              id
              type
              formKey
              label
              details
            }
          }
        }
      }
      tenant {
        id
        timezone
      }
    }
  `
})
