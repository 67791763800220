/* Copyright © 2019 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import cx from 'clsx'
import { find } from 'lodash'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { filtersOnDocList } from '../../components/feature-flags'
import * as Icons from '../../icons'
import PopoverButton from './popover-button'

export const Columns = ({ value, update, className, schema }) => (
  <div
    className={cx(
      'relative overflow-auto dark:bg-light-gray-200 max-[500px]:w-full',
      className
    )}
  >
    <div className='sr-only' id='columnTitle'>
      <Trans id='column.management' />
    </div>
    <div className='sr-only' id='columnDesc'>
      <Trans id='find.controls.here.show.hide' />
    </div>
    <DragDropContext
      onDragEnd={result => {
        if (!result.destination) return
        update(draft => {
          const [column] = draft.columns.splice(result.source.index, 1)
          draft.columns.splice(result.destination.index, 0, column)
        })
      }}
    >
      <Droppable droppableId='droppable'>
        {(provided, snapshot) => (
          <ul
            className='border-t border-light-gray-200 dark:border-light-gray-300'
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {value.map((column, i) => {
              const isInactive =
                column.formKey.startsWith('data.') &&
                schema &&
                filtersOnDocList &&
                !find(schema, { formKey: column.formKey })
              return (
                <Draggable
                  key={column.formKey}
                  draggableId={column.formKey}
                  index={i}
                >
                  {(provided, snapshot) => (
                    <li
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className='relative mt-1 box-border flex min-h-[48px] items-center justify-between border-b border-light-gray-200 bg-white px-2 py-1 dark:border-light-gray-300 dark:bg-light-gray-200'
                    >
                      <div className='flex items-center'>
                        <Icons.Drag
                          mr={1}
                          className='fill-light-gray-500 dark:fill-medium-gray-300'
                          width={14}
                          height={14}
                        />
                        <div
                          className={cx(
                            'block pl-2 text-sm',
                            column.formKey.startsWith('meta.')
                              ? 'mr-[73px]'
                              : isInactive
                                ? 'mr-3'
                                : 'mr-[102px]'
                          )}
                        >
                          {column.label}
                        </div>
                      </div>
                      <div className='flex items-center gap-4'>
                        <div className='whitespace-nowrap rounded-sm bg-light-gray-100 px-2 text-[9px] font-medium uppercase text-dark-gray-500'>
                          {column.formKey.startsWith('meta.')
                            ? i18n._({ id: 'meta.data', message: 'Meta_Data' })
                            : isInactive
                              ? i18n._({
                                  id: 'submission.data.archived',
                                  message: 'Submission_Data (Archived)'
                                })
                              : i18n._({
                                  id: 'submission.data',
                                  message: 'Submission_Data'
                                })}
                        </div>
                        <button
                          className='kp-button-transparent kp-button-icon'
                          aria-pressed={column.visible}
                          onClick={() => {
                            update(draft => {
                              draft.columns[i].visible =
                                !draft.columns[i].visible
                            })
                          }}
                        >
                          <div className='sr-only'>
                            {column.visible
                              ? `Hide Column ${column.label}`
                              : `Show Column ${column.label}`}
                          </div>
                          {column.visible ? (
                            <Icons.Visible />
                          ) : (
                            <Icons.Hidden />
                          )}
                        </button>
                      </div>
                    </li>
                  )}
                </Draggable>
              )
            })}
            {provided.placeholder}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
  </div>
)

const finishedLabel = (
  <>
    <Icons.Visible className='mr-2 fill-blue-500' />
    <Trans id='columns' />
  </>
)

export default ({ value, update, className, schema }) => (
  <PopoverButton
    label={finishedLabel}
    data-testid='popover-columns'
    aria-labelledby='columnTitle'
    aria-describedby='columnDesc'
    buttonProps={{ small: true, transparent: true }}
  >
    {() => (
      <Columns
        value={value}
        update={update}
        className={className}
        schema={schema}
      />
    )}
  </PopoverButton>
)
